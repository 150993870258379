export const relayitemslist=[
    {   id:1,
        name:'AcquaMin. Naturale 0,5LT',
        description:'Aunique shopping experience at the airport with AeliaDuty Free',
        deliveryLocation:'Aeli',
        fleet:'ADR Mall A',
        img:'/assets/images/coke.png',
        price:1.70,
        count:0
    },
    {   id:2,
        name:'Pringles paprika 175gr',
        description:'Aunique shopping experience at the airport with AeliaDuty Free',
        deliveryLocation:'Aeli',
        fleet:'ADR Mall A',
        img:'/assets/images/diet-coke.png',
        price:4.50,
        count:0
    },
    {   id:3,
        name:'Pat. Classica Bar 50gr',
        description:'Aunique shopping experience at the airport with AeliaDuty Free',
        deliveryLocation:'Aeli',
        fleet:'ADR Mall A',
        img:'/assets/images/bottle.png',
        price:2.40,
        count:0
    },
    {   id:4,
        name:'Pringles Original 175gr',
        description:'Aunique shopping experience at the airport with AeliaDuty Free',
        deliveryLocation:'Aeli',
        fleet:'ADR Mall A',
        img:'/assets/images/sprite.png',
        price:4.50,
        count:0
    }
]

export const aeliaItemslist=[
    {   id:1,
        name:'Chianti Riserva Leonardo Cl 75',
        img:'/assets/images/ChiantiRiserva.jpg',
        price:12.00,
        count:0
    },
    {   id:2,
        name:'M&Ms Candy Tin 200g',
        img:'/assets/images/M&Mcandy.jpg',
        price:8.90,
        count:0
    },
    {   id:3, 
        name:'Pat. Classica Bar 50gr',
        img:'/assets/images/BaciTurbo.jpg',
        price:4.90,
        count:0
    },
    {   id:4,
        name:'Kinder Surprise Aviator',
        img:'/assets/images/KinderSurprise.jpg',
        price:25.50,
        count:0
    }
]