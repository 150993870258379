import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { getlocationlistmapId } from '../API';
import Header from './Header';
import { BottomNavigation, BottomNavigationAction, InputLabel, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

function SelectDelivery() {
    const [deliverylocation,setdeliverylocation] =useState()
    const [activenextbtn ,setactivenextbtn] =useState(false)
    const {t}  = useTranslation() 
    useEffect(()=>{
        getlocationlistmapId().then((res)=>{
             console.log('Location List Map Id', res)
             setdeliverylocation(res.data.data)
        }).catch((err)=>{
             console.log('Error in Map Id Locations',err)
        })
   
      },[])
   const cartData = useLocation()
   const navigate = useNavigate()   

  var dlocation = localStorage.getItem('deliveryLocation')
  const validationSchema = yup.object({
    deliverylocation :yup.string().required('Please Select Delivery location'),
    pickuplocation:yup.string().required('Please select Pickup Location')
})  
  const formik = useFormik({
    initialValues: {
      deliverylocation:'',
      pickuplocation:process.env.REACT_APP_PICKUP_ID?process.env.REACT_APP_PICKUP_ID:''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      localStorage.setItem('deliveryLocation', values.deliverylocation)
      sessionStorage.setItem('pickupId',values.pickuplocation)
      navigate('/details', {state:{locationName:values.deliverylocation,pickuplocation:values.pickuplocation,cartdata:cartData}})
    }
  })
  let newlocation=[]
    return (
        <>
     <div className='MainWhole-wrapper-container'>    
        <Header/>   
      <div className="DetailsPage-Main-wrapper">
        {!process.env.REACT_APP_PICKUP_ID&&
          <div className='Select_delivery_page_inner_wrapper'> 
               <h2> Select your Pickup Location</h2>
               <div className='Select_delivery_locations_list_wrapper'>  
                <BottomNavigation
                    showLabels
                    name="pickuplocation"
                    value={formik.values.pickuplocation}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('pickuplocation',newValue)
                    }}
                    style={{ flexWrap:'wrap', position:'relative', display:'inline-block', textAlign:'start' , height:'auto',background:' transparent'}}
                    error={formik.touched.pickuplocation && Boolean(formik.errors.pickuplocation)}
                    helperText={formik.touched.pickuplocation && formik.errors.pickuplocation} 
                >
                  {deliverylocation?.map((item)=>{
                   if(item.locationType==="PICKUP" && item.status==="ACTIVE"  )
                    {
                      return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 24px',backgroundColor:'white' , margin:'10px !important',border: "1px solid lightgrey",borderRadius:'30px',fontSize:'15px', fontFamily:'Poppins'}} />
                    } 
                })}
                </BottomNavigation>
                {(formik.touched.pickuplocation &&formik.errors.pickuplocation)? 
              <p style={{color:'#d32f2f', textAlign:'left', marginTop:'10px',fontSize:'11px', paddingLeft: '27px'}}> Please Select Pickup Location</p>:null }
      </div>

          </div>
}
         <h2 style={{marginTop:"25px !important"}}> {t('cart_page_delivery_location')}</h2>
          {/* {console.log('deliverylocation',deliverylocation)} */}
          <form onSubmit={formik.handleSubmit} 
          // style={{height:'100vh'}}
          > 
        <div className='Select_delivery_page_inner_wrapper'> 
         <div className='Select_delivery_locations_list_wrapper'>  
             <BottomNavigation
                showLabels
                name="deliverylocation"
                value={formik.values.deliverylocation}
                onChange={(event, newValue) => {
                    formik.setFieldValue('deliverylocation',newValue)
                }}
                style={{ flexWrap:'wrap', position:'relative', display:'inline-block', textAlign:'start' , height:'auto',background:' transparent'}}
                error={formik.touched.deliverylocation && Boolean(formik.errors.deliverylocation)}
                helperText={formik.touched.deliverylocation && formik.errors.deliverylocation} 
            >
                 {deliverylocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE"  )
                    {
                      return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 24px',backgroundColor:'white' , margin:'10px !important',border: "1px solid lightgrey",borderRadius:'30px',fontSize:'15px', fontFamily:'Poppins'}} />
                    } 
                })}
            </BottomNavigation>
            {(formik.touched.deliverylocation &&formik.errors.deliverylocation)? 
          <p style={{color:'#d32f2f', textAlign:'left', marginTop:'10px',fontSize:'11px', paddingLeft: '27px'}}> {t('Detail_page_please_select_delivery_location')}</p>:null }
      </div>  
        </div>
        <button className="ItemList_Page_Next_button" type="submit"> {t('Item_list_two_page_Nextbtn_text')} </button> 
       
        </form>
    </div>
        
   </div> 
    </>  
  )
}

export default SelectDelivery
