import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import Header from "../components/Header"
import PaymentForm from "./PaymentForm"
import { useTranslation } from "react-i18next";
const PUBLIC_KEY = "pk_test_51Ll9yWSDFNnmZyfV228dqFJEMinsbKWEyPVrcGn6mBmLwvi6EMCxUS2e3STTEMxMfore0nxj8kEjohIsB1E3ATNx00fc9WBklk"

const stripeTestPromise = loadStripe(PUBLIC_KEY)



export default function Checkout() {

const {t} = useTranslation()
	return (

		<>
		<div className='MainWhole-wrapper-container'> 
		<Header/>
		<div className="Checkout-StripeMain-wrapper">
			<h1 >
			{/* stripe  */}
				{t('checkout_page_heading')}
			</h1>
			<h3> 
			{/* Enter your Card Details */}
				{t('checkout_page_enter-details')}
				</h3>  
			<Elements stripe={stripeTestPromise} >
				<PaymentForm />
			</Elements>
		</div>	
    	</div>

		</>
	)
}
