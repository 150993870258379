export const SliderData=[
    {
      imgurl:"assets/images/Slider_image_1.jpg", 
      imageContent:"Place your order",
      StepContent:'Step 1' 
    },
    {
        imgurl:"/assets/images/Slider_image_2.jpg", 
        imageContent:"Order prepared and loaded in robot",
        StepContent:'Step 2' 
      }
    ,
    {
        imgurl:"/assets/images/Slider_image_3.jpg", 
        imageContent:"Robot drives to you",
        StepContent:'Step 3' 
      },
      {
        imgurl:"/assets/images/Slider_image_4.jpg", 
        imageContent:"You get notified when robot arrives",
        StepContent:'Step 4' 
      },
      {
        imgurl:"/assets/images/Slider_image_5.jpg", 
        imageContent:"Scan QR code",
        StepContent:'Step 5' 
      },
      {
        imgurl:"/assets/images/Slider_image_6.jpg", 
        imageContent:"Door opens, take order",
        StepContent:'Step 6' 
      }
      
]