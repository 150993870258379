import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { useTranslation } from "react-i18next";


function Help() {

  const navigate= useNavigate()  

  const {t}= useTranslation() 
  return (
    <div className='MainWhole-wrapper-container'>   
      
       <Header/>
      <div className='Help-Page-Main-content-wrapper'>
      <div className='Help-page-Header-div'>
         <div className='Help-page-Header-inner-div-one'>
          <h4 className='Help-page-Header-div-h4'> 
         {/* {t('Help_page_Main_heading')} */}
         HELP & FAQ
         </h4>
         </div>
       
     </div>
       <Accordion style={{marginTop:'1rem'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{  margin:''}}
           >
          <Typography > <p className='Help_page_content_heading'>
           {/* {t('Help_page_accordian_heading_one')}  */}
           Refund Policy
           </p></Typography>
          </AccordionSummary>
          <AccordionDetails>
           <Typography>
                <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_1')}</p>
                <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_2')}</p>
                <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_3')}</p>
                <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_4')}</p>
                    <h3 className='Help_page-content-h3'> {t('Help_page_accordian_content_h3_1')}</h3>
                    <h6 className='Help_page-content-h6'> {t('Help_page_accordian_content_h6_1')}</h6>
                    <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_5')}</p>
                    <h6 className='Help_page-content-h6'> {t('Help_page_accordian_content_h6_2')}</h6>
                    <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_6')}</p>
            <ul> 
                <li className='Help_page-content-p'>{t('Help_page_accordian_content_li_1')}</li>
                <li className='Help_page-content-p'> {t('Help_page_accordian_content_li_2')}</li>
                <li className='Help_page-content-p'> {t('Help_page_accordian_content_li_3')}</li>
                <li className='Help_page-content-p'> {t('Help_page_accordian_content_li_4')}</li>
                <li className='Help_page-content-p'> {t('Help_page_accordian_content_li_5')}</li>
                <li className='Help_page-content-p'> {t('Help_page_accordian_content_li_6')}</li>
            </ul>
            <h6 className='Help_page-content-h6'> {t('Help_page_accordian_content_h6_3')}</h6>  
                <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_7')}</p>
                <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_8')}</p>
           <h6 className='Help_page-content-h6'> {t('Help_page_accordian_content_h6_4')}</h6>
              <p className='Help_page-content-p'> {t('Help_page_accordian_content_p_9')}</p>
           <ul> <li className='Help_page-content-p'> {t('Help_page_accordian_content_p_10')}</li></ul>
         
         </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ margin:''}}
        >
    <Typography><p className='Help_page_content_heading'> {t('Help_page_accordian_heading_two')} </p></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_1')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_2')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_3')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_4')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_1')}</h6> 
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_5')}</p> 
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_6')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_2')}</h6>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_7')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_8')}</p>
          <ul>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_9')}</li>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_10')}</li>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_11')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_12')}</li>
          </ul>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_13')}</p>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_14')}</p>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_15')}</p>
          <ul> 
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_16')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_17')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_18')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_19')}</li>
          </ul>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_20')}</p>
          <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_3')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_21')}</p>
          <ul> 
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_22')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_23')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_24')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_25')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_26')}</li>
          </ul>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_27')}</p>
           <p className='Help_page-content-p'>  {t('Help_page_accordian_2_content_p_28')}</p>
          <ul> 
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_29')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_30')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_31')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_32')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_33')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_34')}</li>
            <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_35')}</li>
          </ul>
           <p className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_36')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_37')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_38')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_39')}</p>
          <ul>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_40')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_61')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_62')}</li>
          </ul>
          <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_63')}</p>
          <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_4')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_64')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_5')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_65')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_6')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_66')}</p> 
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_7')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_67')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_8')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_68')}</p>
         <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_69')}</p>
          <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_9')}</h6>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_70')}</p>
           <ul>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_71')} </li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_72')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_73')}</li>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_74')} </li>
          </ul>
          <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_75')}</p>
          <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_76')}</p>
           </Typography>
         </AccordionDetails>
      </Accordion>
    </div>
    </div>
  )
}

export default Help