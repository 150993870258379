import React, { useEffect, useState } from 'react'
import Header from './Header'
import $ from 'jquery'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { emphasize } from '@mui/material';
import { getdeliverytime, OrderQrcode, sendmsglocation } from '../API';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OTPInput, { ResendOTP } from "otp-input-react";

function Congratulations() {
  
  const [remaintime, setremaintime]=useState()
  const [open, setOpen] = React.useState(false);
  const [opentwo, setOpentwo] = React.useState(false);
  const [otp, setOtp] = useState(1234); 
  var rString=''
  const orderData =useLocation()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor:'#ffffff',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px',
    paddingTop: '11px',
    paddingBottom:'0px',
    border:'none',
    paddingRight:'0px',
    paddingLeft:'0px',
    zIndex:'999999999999999999999999'
  };
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  }
  const handleClosetwo = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpentwo(false);
  }

const handleOpen = () => setOpen(true);

const handleopentwo = () => setOpentwo(true);
  const [newstring, setnewstring] =useState()
   useEffect(()=>{

    // generateBarCode(orderData.state.newstring)
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hrs= today.getHours(); // => 9
    let mins=today.getMinutes(); // =>  30
    var ampm = hrs >= 12 ? 'pm' : 'am';
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12
    if (dd < 10){
      dd = '0' + dd;
    } 
    if (mm < 10){
      mm = '0' + mm;
    } 
    if(hrs < 10){
      hrs ='0'+hrs
    }
    if(mins < 10){
      mins ='0'+mins
    }
    const formattedToday = mm + '-' + dd + '-' + yyyy + '\xa0\xa0\xa0\xa0\xa0'+hrs +":" + mins +'\xa0\xa0'+ ampm;
    document.getElementById('DATE').innerHTML = formattedToday;
   setInterval(()=>{
    getdeliverytime(orderData.state.newstring).then((res)=>{
      // console.log('Response timer',res)
      setremaintime(res.data.data[0].deliveryTime)
     }).catch((err)=>{
         console.log(err)
     })
   }, 4000)  
  
   },[])

    function generateBarCode(orderId)
    {
        var url = 'https://api.qrserver.com/v1/create-qr-code/?data=' + orderId + '&amp;size=50x50';
        $('#barcode').attr('src', url);
        OrderQrcode(orderId,url).then((res)=>{
             console.log('QRlInk reposne',res)
        }).catch((err)=>{
          console.log(err)
        })
        return rString
    }
 
    const { t } = useTranslation() 
  //   function startTimer(duration, display) {
  //     var timer = duration, minutes, seconds;
  //     setInterval(function () {
  //         minutes = parseInt(timer / 60, 10)
  //         seconds = parseInt(timer % 60, 10);
  
  //         minutes = minutes < 10 ? "0" + minutes : minutes;
  //         seconds = seconds < 10 ? "0" + seconds : seconds;
  
  //         display.textContent = minutes + ":" + seconds;
  
  //         if (--timer < 0) {
  //             timer = duration;
  //         }
  //     }, 1000);
  // }
  
  // window.onload = function () {
  //     var fiveMinutes = 60 * 20,
  //         display = document.querySelector('#time');
  //     startTimer(fiveMinutes, display);
  // };
    return (
    
     <>
      <div className='MainWhole-wrapper-container'>  
      {console.log('orderIdorderId',orderData)}
     <Header open={open} opentwo={opentwo}/>
    <div className='Congratulation-Main-wrapper'>
       <div className='Congratulation-content-div-one-wrapper'>
             <div className='Congratulation-content-div-one-wrapper-inner-one'> 
               <img src="/assets/images/order-placed.gif"/>    
            </div>
           <div className='Congratulation-content-div-one-wrapper-inner-two'>
                <h1> 
                  {/* {t('congratulations_page_heading')} */}
                    Congratulations
                  </h1>
                <p> Your Order has been placed </p> 
                
           </div>
       </div>
       <div className='Congratulation-page-order-wrapper-below-QR'> 
        <div> 
            <h5 > {t('congratulations_page_OrderID')}:</h5>
            <p> {orderData.state.newstring} </p>
        </div>    
        <div> 
            <h5 >{t('congratulations_page_Order_date')}:</h5>
            <p id="DATE">  </p>
        </div>
        <div> 
          <h5> Delivery Location </h5>
          <p> {orderData.state.cartdata.state.locationName}</p>
        </div>
     </div> 
    <div className="Congratulations_page_QRcode_pin_wrapper">
         <h3> Get your item with the below options</h3>  
         <div className='Congratulations_page_QRcode_pin_inner'> 
            <button onClick={handleOpen}> Scan QR Code</button>
            <p> OR</p>
            <button onClick={handleopentwo}> Enter PIN</button>
         </div> 
    </div> 

     <Modal     open={open}
                // BackdropProps={false}
                onHide={handleClose}
                // onClose={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">  
      <Box sx={style}>
          <div className='QR-Code-wrapper-whole'> 
            <div className='QR-Code-wrapper-whole-inner-one'>
              <h3> Scan QR Code</h3>
              <div className='Sorry_div_wrapper_inner_div' style={{paddingBottom:'0px'}} onClick={handleClose}>
                 <HighlightOffIcon style={{fontSize:'35px', margin:'5px'}}/>
                 <p style={{fontSize:'17px'}}>Close</p> 
             </div> 
            </div>       
            <p style={{marginBottom:'12px'}}>  Show the QR code to the camera</p>
            <div className='QR-Code-main-inner'> 
          {/* <img id='barcode' 
                src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100" 
                alt="" 
                title="Congratualtions" 
                width="500" 
                height="500"
                style={{objectFit:'cover'}} /> */}
                <img src="/assets/images/QR_CODE_AS223.jfif" style={{objectFit:'cover'}} width="500" 
                height="500"/>
            </div>      
        </div>
        </Box>
        </Modal> 
        <Modal     open={opentwo}
                // BackdropProps={false}
                onHide={handleClosetwo}
                // onClose={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">  
      <Box sx={style}>
          <div className='QR-Code-wrapper-whole'> 
            <div className='QR-Code-wrapper-whole-inner-one'>
              <h3> Your PIN</h3>
              <div className='Sorry_div_wrapper_inner_div' style={{paddingBottom:'0px'}} onClick={handleClosetwo}>
                 <HighlightOffIcon style={{fontSize:'35px', margin:'5px'}}/>
                 <p style={{fontSize:'17px'}}>Close</p> 
             </div> 
            </div>       
            <p style={{marginBottom:'12px'}}>  Enter the PIN on the Top Screen</p>
            <div className='QR-Code-main-inner'> 
               {/* <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false} className="Cart_page_otp_input" /> */}
                <h3 className='Congratulation_pin'> 1 2 3 4</h3>
            </div>      
        </div>
        </Box>
        </Modal>
     {/* <div className='QR-Code-wrapper-whole-inner-one'>
           <h4>Estimated time for delivery<br />is XX minutes.</h4>
          </div> */}
     <div className="Congratulation-page-invoice-wrapper"> 
            
            <h3> Your Order Details</h3>
            <table className="Congratulation-page-invoice-wrapper-table-whole">

               <tbody>
                {orderData.state.cartdata.state.cartData.state[0].map((item)=>{
                 if(item.count>0){
                  return(
                                    <tr> 
                      <td style={{textAlign:'left'}}> {item.itemName}</td>
                    
                      <td style={{textAlign:"right"}}> X {item.count}</td>
                   </tr>
                    )
                  }
                })}
                
               </tbody>
            </table>
          {/* <div className='Congratulation-page-subtotal-wrapper'> 
            <h4> <span> {t('congratulations_page_table_Subtotal')}</span> : 1,70</h4>
            <h4> <span> {t('congratulations_page_table_Delivery_charges')} </span>:  1,70</h4>
         </div>  
         <div  className='Congratulation-page-total-wrapper'>
          <p><span> {t('congratulations_page_table_Total')} </span>:  1,70</p>
         </div>    */}
     </div>
    </div>
   </div> 
   <Footer open={open} opentwo={opentwo}/>
    </>
  )
}

export default Congratulations