import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";

const CARD_OPTIONS = {
	iconStyle: "solid",
    label:"Card Number",
	style: {
		base: {
			iconColor: "#000000",
			color: "#000000",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#000000" },
			"::placeholder": { color: "#000000" }
		},
		invalid: {
			iconColor: "#d32f2f",
			color: "#d32f2f"
		}
	}
}

export default function PaymentForm() {
    const [success, setSuccess ] = useState(false)
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })


    if(!error) {
        try {
            const {id} = paymentMethod
            const response = await axios.post("http://localhost:4000/payment", {
                amount: 1,
                id
            })

            if(response.data.success) {
                console.log("Successful payment")
                setSuccess(true)
                setTimeout(()=>{

                    navigate('/congratulations')
                },2000)
                
            }

        } catch (error) {
            console.log("Error", error)
        }
    } else {
        console.log(error.message)
    }
}

    return (
        <>
        {/* {!success ?  */}
        <form onSubmit={handleSubmit} className="CheckoutStripe-content-wrapper">
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <CardElement options={CARD_OPTIONS}/>
                </div>
            </fieldset>
            <button>
            {/* Pay Now */}
                {t('checkout_page_pay_now_button')}
                </button>
        </form>
        {/* :
       <div>
           <h2>You just bought a sweet spatula congrats this is the best decision of you're life</h2>
       </div> 
        } */}
            
        </>
    )
}
