import React from 'react'
import Footer from './Footer'
import LandingPageHeader from './LandingPageHeader'

function HomeSecond() {
  return (
  <>
    <div className='MainWhole-wrapper-container-2'>      
       <LandingPageHeader/>
        <div className='HomePage-Main-Wrapper-Container'>
            <h1 className="HomePage-Main-Heading-text"> Help us test a <span>Robot</span> and get a <span>FREE DRINK</span></h1>               
              <div className='HomePage_second_Youtube_content_wrapper'> 
                <h4 className="HomePage_How_It_Works_text_second" > How It Works</h4>
                  <iframe src='https://www.youtube.com/embed/knYMcZfIA6Q?controls=0&showinfo=0&modestbranding=1&autohide=1'
                     frameborder='0'
                     allow='autoplay; encrypted-media'
                     allowfullscreen
                     title='video'
                     className='HomePage_Youtube_Video'
                   />
                 <button className='HomePage_order_now_button_second'> Order Now</button>    
             </div> 
                            
        </div>  
         <div className="HomePage_section_two_wrapper"> 
            <img src="/assets/images/xbridge-language.png"/>
         </div>
        <Footer/> 
    </div>
  </>
  )
}

export default HomeSecond