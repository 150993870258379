import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import '../src/CSS/custom.css'
import ItemsList from './components/ItemsList';
import Details from './components/Details';
import Cart from './components/Cart';
import Congratulations from './components/Congratulations';
import Checkout from './stripepayment/Checkout';
import Help from './components/Help';
import MoreDetails from './components/MoreDetails';
import HomeSecond from './components/HomeSecond';
import ItemsListTwo from './components/ItemListTwo';
import SelectDelivery from './components/SelectDelivery';

function App() {
  const location =useLocation()
   
    var pathname=window.location.host.split(".")[0]

    if(pathname==='sf'){
      sessionStorage.setItem('mapId','63f6925f17c8bb2edc3146fd')
      sessionStorage.setItem('pickupId','KITCHEN')
      sessionStorage.setItem('fleetId','63f60cf32d120d2ed6f3f741' )
    }
    else if(pathname==='la'){
      sessionStorage.setItem('mapId','6389923e3de3c52312ac855c')
      sessionStorage.setItem('pickupId','KITCHEN')
      sessionStorage.setItem('fleetId','639071fc9b38502eefe86932')
    }
    else if(pathname==='bs'){
      sessionStorage.setItem('mapId','63f6925f17c8bb2edc3146fd')
      sessionStorage.setItem('pickupId','KITCHEN')
      sessionStorage.setItem('fleetId','63f60cf32d120d2ed6f3f741')
    }
    else{
      sessionStorage.setItem('mapId','6389923e3de3c52312ac855c')
      sessionStorage.setItem('pickupId','KITCHEN')
      sessionStorage.setItem('fleetId','639071fc9b38502eefe86932')
    }
  return (
   
    <div className="App">
        {console.log('window.location.host.split(".")[0]',window.location.host.split(".")[0])}
       <Routes>
          <Route path="/" element={<Home/>}/> 
          <Route path="/home" element={<Home/>}/>
          <Route path="/homesecond" element={<HomeSecond/>}/>
          <Route path="/Itemslist" element={<ItemsList/>}/> 
          <Route path="/ItemslistTwo" element={<ItemsListTwo/>}/> 
          <Route path="/Details" element={<Details/>}/>
          <Route path="/cart" element={<Cart/>}/> 
          <Route path="/congratulations" element={<Congratulations/>}/>  
          <Route path="/checkout" element={<Checkout/>}/> 
          <Route path="/help" element={<Help/>}/>  
          <Route path="/moredetails" element={<MoreDetails/>}/>  
          <Route path="/selectDetails" element={<SelectDelivery/>}/>  
       </Routes>
       {console.log('location',location)}
    </div>
  );
}

export default App;
