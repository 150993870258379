import React from 'react'

function Footer() {
  return (
    <div className="Footer_Content_wrapper">
        <p> Powered By</p>
        <img src="/assets/images/ottonomy-logo-new.png"/>
    </div>
  )
}

export default Footer